import React from 'react';
import dataText from '../components/gui/gui-typo/data';
import StandardPage from '../components/page/page-standard';

const pageName = 'personal-discount-page';

const itemsData = [
  {
    title: 'Начисление персональных скидок',
  },
  {
    title: 'Снижение персональных скидок',
  },
  {
    title: 'Увеличение персональных скидок',
  },
];

// eslint-disable-next-line no-param-reassign,no-return-assign
itemsData.forEach((item) => item.text = dataText.listItem);

const PersonalDiscountPage = () => (

  <StandardPage pageName={pageName} title="Персональные скидки">
    <ol className="list list_num">
      {itemsData.map((item, key) => (
        // eslint-disable-next-line react/no-array-index-key
        <li className="list__item" key={key}>
          <div className="list__item-title page__title page__title_h2">{item.title}</div>
          <div className="list__item-body page__limited-text">{item.text}</div>
        </li>
      ))}
    </ol>
  </StandardPage>
);

export default PersonalDiscountPage;
